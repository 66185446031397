<template>
    <div class="info-box-container">
        <h3>{{ $t(`ProductionProgress.ProgressTab.ProgressInfo.${cardTitle}.${title}`) }}</h3>

        <div class="info-container">
            <span>{{ content }}</span>
        </div>
    </div>
</template>

<script>
export default {
    computed: {
    },
    props: {
        cardTitle: {
            type: String,
            default: '',
            required: true
        },
        title: {
            type: String,
            default: '',
            required: true
        },
        content: {
            type: String,
            default: '',
            required: true
        }
    }
}
</script>

<style lang="scss" scoped>
.info-box-container {
    display: flex;
    flex-direction: column;

    h3 {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #998F8A;
        margin: 0;
    }

    .info-container {
        display: flex;
        line-height: 0;

        span {
            font-weight: 600;
            font-size: 22px;
            line-height: 30px !important;
            color: #4C4541;
        }
    }

}

@media (max-width: 600px) {
    .info-box-container {
        flex-direction: row !important;
        justify-content: space-between;
        align-items: center;
        gap: 0 16px;

    }
}

@media (max-width: 480px) {
    .info-box-container {
        flex-direction: row !important;
        justify-content: space-between;
        align-items: center;
        gap: 0 16px;

        h3 {
            font-size: 12px !important;
            line-height: 16px !important;
        }

        .info-container {
            span {
                font-size: 14px !important;
                line-height: 20px !important;
            }
        }
    }
}
</style>
