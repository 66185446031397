<template>
    <div class="progress-info-container">
        <div
            class="border-object"
            :style="`
                background: ${ title === 'OverTime' ? '#EF5350' : '#66BB6A' };
            `"
        />
        <div class="progress-info-content-wrapper">
            <h3 class="progress-info-title">
                {{ $t(`ProductionProgress.ProgressTab.ProgressCard.Others.${title}`) }}
            </h3>
            <span class="progress-info-content">
                {{ percentage }}%
            </span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: 'Forecast' | 'OverTime',
            required: true
        },
        percentage: {
            type: Number,
            required: true
        }
    }
}
</script>

<style lang="scss" scoped>
.progress-info-container {
    width: 100%;
    display: flex;

    .border-object {
        width: 4px;
        display: block;
        border-radius: 60px;
    }

    .progress-info-content-wrapper {
        flex-grow: 1;
        padding-left: 10px;

        display: flex;
        flex-direction: column;
        .progress-info-title {
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            color: #998F8A;

            margin: 0 0 8px;
        }

        .progress-info-content {
            font-weight: 600;
            font-size: 22px;
            line-height: 30px;
            color: #4C4541;
        }
    }
}

@media (max-width: 480px) {
    .progress-info-container {
        width: 100%;
        display: flex;
        .progress-info-content-wrapper {
            flex-grow: 1;
            padding-left: 10px;

            display: flex;
            flex-direction: column;

            .progress-info-title {
                font-size: 12px !important;
                line-height: 16px !important;
            }

            .progress-info-content {
                font-size: 14px !important;
                line-height: 20px !important;
            }
        }
    }
}
</style>
