var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"progress-bar-container"},_vm._l((_vm.computedData),function(ref,index){
var percent = ref.percent;
var color = ref.color;
return _c('div',{key:index,class:[
            'bar',
            {
                'border-radius-left': index === _vm.firstIndexOfBorder,
                'border-radius-right': index === _vm.lastIndexOfBorder
            }
        ],style:(("\n            width: " + percent + "%;\n            background-color: " + color + ";\n        "))})}),0)}
var staticRenderFns = []

export { render, staticRenderFns }