<template>
    <div class="status-info-container">
        <h2 class="status-info-title">
            Status
        </h2>

        <ProgressBar :data="data"/>

        <div class="info-list-container">
            <StatusInfoBox
                v-for="item, index in data"
                :key="`item-${index}`"
                :data="item"
            />
        </div>
    </div>
</template>

<script>
import ProgressBar from './components/ProgessBar';
import StatusInfoBox from './components/StatusInfoBox';

export default {
    components: {
        ProgressBar,
        StatusInfoBox
    },
    props: {
        data: {
            type: Array,
            default: [],
            required: true
        }
    }
}
</script>

<style lang="scss" scoped>
.status-info-container {
    .status-info-title {
        font-weight: 600;
        font-size: 26px;
        line-height: 36px;
        color: #4C4541;

        margin: 24px 0 0;
    }

    .info-list-container {
        padding: 0 16px;
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        gap: 16px;
    }
}

@media (max-width: 690px) {
    .status-info-container {
        .info-list-container {
            grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
        }
    }
}

@media (max-width: 480px) {
    .status-info-container {
        .status-info-title {
            font-size: 18px !important;
            line-height: 26px !important;
        }
    }
}
</style>
