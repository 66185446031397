<template>
    <div class="progress-bar-container">
        <div
            :class="[
                'bar',
                {
                    'border-radius-left': index === firstIndexOfBorder,
                    'border-radius-right': index === lastIndexOfBorder
                }
            ]"
            v-for="{ percent, color }, index in computedData"
            :key="index"
            :style="`
                width: ${ percent }%;
                background-color: ${ color };
            `"
        />
    </div>
</template>

<script>

export default {
    props: {
        data: {
            type: Array,
            default: [],
            required: true
        }
    },
    computed: {
        computedData() {
            const createdValue = this.data.map(item => ({
                ...item,
                percent: this.calculateProportion(parseFloat(item.percent))
            }))

            return createdValue;
        }
    },
    data: () => ({
        firstIndexOfBorder: null,
        lastIndexOfBorder: null
    }),
    mounted() {
        this.setBorderRadiusInFirstAndLastBar();
    },
    methods: {
        calculateProportion(value) {
            let percentTotal = 0;

            this.data.forEach(({ percent }) => {
                percentTotal += parseFloat(percent);
            });

            const proportionValue = 100 * value / percentTotal;

            return proportionValue;
        },
        setBorderRadiusInFirstAndLastBar() {
            const { computedData } = this;
            const reverseArray = [...computedData].reverse();

            this.firstIndexOfBorder = computedData.findIndex(({ percent }) => percent > 0);
            this.lastIndexOfBorder =  (reverseArray.length - 1) - reverseArray.findIndex(({ percent }) => percent > 0);
        }
    }
}
</script>

<style lang="scss" scoped>
.progress-bar-container {
    width: 100%;
    margin: 16px 0;

    display: flex;

    .bar {
        height: 16px;

        &.border-radius-left {
            border-top-left-radius: 16px;
            border-bottom-left-radius: 16px;
        }

        &.border-radius-right {
            border-top-right-radius: 16px;
            border-bottom-right-radius: 16px;
        }
    }
}
</style>
