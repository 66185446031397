<template>
    <div class="status-info-box">
        <span class="status-info-title">
            {{ data.name }}
        </span>
        <div class="status-info-content-wrapper">
            <div
                class="border-object"
                :style="`background: ${data.color}`"
            />
            <span class="status-info-content">
                {{ data.percent }}% - {{ data.hours }} h
            </span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            default: () => ({}),
            required: true
        }
    },
}
</script>

<style lang="scss" scoped>
.status-info-box {
    display: flex;
    flex-direction: column;
    width: 100%;

    .status-info-title {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: #998F8A;
    }

    .status-info-content-wrapper {
        display: flex;
        margin-top: 8px;

        .border-object {
            width: 5px;
            border-radius: 40px;
            display: block;
        }

        .status-info-content {
            font-weight: 600;
            font-size: 22px;
            line-height: 30px;
            color: #4C4541;
            padding-left: 8px;

            flex-grow: 1;
        }
    }
}

@media (max-width: 480px) {
    .status-info-box {
        .status-info-title {
            font-size: 12px !important;
            line-height: 16px !important;
        }

        .status-info-content-wrapper {
            .status-info-content {
                font-size: 14px !important;
                line-height: 20px !important;
            }
        }
    }
}
</style>
