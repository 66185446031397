<template>
    <div class="card-info">
        <h2>{{ $t(`ProductionProgress.ProgressTab.ProgressInfo.${cardTitle}.title`) }}</h2>
        <div :class="[
            'infos-container',
            {
                'quantity-card': cardTitle === 'quantity',
                'my-work-card': cardTitle === 'myWork',
                'forecast-time-card': cardTitle === 'forecastTime',
                'total-time-card': cardTitle === 'totalTime'
            }
        ]">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        cardTitle: {
            type: String,
            default: '',
            required: true
        }
    }
}
</script>

<style lang="scss" scoped>
.card-info {
    padding: 24px 0 23px;

    border-bottom: 1px solid #CFC4BE;

    &:first-child {
        padding: 23px 0 !important;
        border-top: 1px solid #CFC4BE;
    }

    h2 {
        font-weight: 600;
        font-size: 26px;
        line-height: 36px;
        color: #4C4541;
        margin: 0;
    }

    .infos-container {
        margin-top: 16px;
        gap: 16px 30px;
        display: grid;

        &.quantity-card {
            grid-template-columns: repeat(4, minmax(0, 1fr));
        }

        &.forecast-time-card,
        &.total-time-card {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }

        &.my-work-card {
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }
    }
}

@media (max-width: 600px) {
    .card-info {
        .infos-container {
            gap: 8px 16px !important;

            &.quantity-card, &.forecast-time-card,
            &.total-time-card, &.my-work-card {
                grid-template-columns: minmax(0, 1fr) !important;
            }
        }
    }

}


@media (max-width: 480px) {
    .card-info {
        .infos-container {
            gap: 8px !important;
        }

        h2 {
            font-size: 18px !important;
            line-height: 26px !important;
        }

        .observation {
            font-size: 12px !important;
            line-height: 16px !important;
        }
    }
}
</style>
