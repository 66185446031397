var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-info"},[_c('h2',[_vm._v(_vm._s(_vm.$t(("ProductionProgress.ProgressTab.ProgressInfo." + _vm.cardTitle + ".title"))))]),_c('div',{class:[
        'infos-container',
        {
            'quantity-card': _vm.cardTitle === 'quantity',
            'my-work-card': _vm.cardTitle === 'myWork',
            'forecast-time-card': _vm.cardTitle === 'forecastTime',
            'total-time-card': _vm.cardTitle === 'totalTime'
        }
    ]},[_vm._t("default")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }