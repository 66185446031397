<template>
    <div class="progress-circle-container">
        <vc-donut
            background="white"
            foreground="#F4EEE8"
            unit="px"
            :size="donutSize"
            :thickness="30"
            :total="100"
            :start-angle="-90"
            :auto-adjust-text-size="true"
            :sections="sections"
        >
            <h3 class="total-title">Total</h3>
            <span class="progress-total">{{ percentage }}%</span>
        </vc-donut>
    </div>
</template>

<script>
export default {
    props: {
        percentage: {
            type: Number,
            required: true
        }
    },
    data: () => ({
        sections: [],
        donutSize: 192,
    }),
    mounted() {
        this.setSections();
    },
    watch: {
        percentage() {
            this.setSections();
        }
    },
    methods: {
        setSections() {
            if (this.percentage <= 100) {
                this.sections = [{ value: this.percentage, color: '#66BB6A' }]
                return;
            }

            this.sections = [
                { value: 100 * 100 / this.percentage, color: '#66BB6A' },
                { value: 100 * (this.percentage - 100) / this.percentage, color: '#EF5350' },
            ]
        },
    }
}
</script>

<style lang="scss" scoped>
.progress-circle-container {
    .total-title {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #998F8A;
        margin: 0;
    }

    .progress-total {
        font-weight: 600;
        font-size: 22px;
        line-height: 30px;
        color: #4C4541;
    }
}
</style>
