<template>
    <div>
        <InfoCard
            v-for="[ card, fields ], index in Object.entries(data)"
            :key="`info-card-${index}`"
            :card-title="card"
        >
            <InfoBox
                v-for="[ title, value ], index in Object.entries(fields)"
                :key="`box-${index}`"
                :card-title="card"
                :title="title"
                :content="value"
            />
        </InfoCard>
    </div>
</template>

<script>
import InfoBox from './components/InfoBox';
import InfoCard from './components/InfoCard';

export default {
    components: {
        InfoCard,
        InfoBox
    },
    props: {
        data: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
    }
 }
</script>

<style lang="scss" scoped>
</style>
