<template>
    <div class="progress-card-container">

            <ProgressTitle :progressName="progressName"/>
            <div class="content-container">
                <ProgressCircle :percentage="percentage" />
                <div class="progress-card-infos-container">
                    <ProgressInfo
                        :title="infoTitle"
                        :percentage="percentage <= 100 ? percentage : 100"
                    />
                    <ProgressInfo
                        v-if="percentage > 100 && progressName === 'Real'"
                        title="OverTime"
                        :percentage="percentage - 100"
                    />
                </div>
            </div>
    </div>
</template>

<script>
import ProgressCircle from './components/ProgressCircle';
import ProgressInfo from './components/ProgressInfo';
import ProgressTitle from './components/ProgressTitle';

export default {
    components: {
        ProgressTitle,
        ProgressCircle,
        ProgressInfo
    },
    props: {
        progressName: {
            type: 'Real' | 'Forecast',
            default: '',
            required: true
        },
        percentage: {
            type: Number,
            required: true
        }
    },
    computed: {
        infoTitle() {
            return this.progressName === 'Real' ? 'Completed' : 'Forecast';
        }
    }
}
</script>

<style lang="scss" scoped>
.progress-card-container {
    width: 100%;
    padding: 16px;
    border: 1px solid #ECE0DB;
    border-radius: 6px;
    min-height: 360px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    .content-container {
        display: flex;
        align-items: center;

        .progress-card-infos-container {
            display: flex;
            flex-direction: column;
            padding-left: 30px;
            gap: 16px;
        }
    }
}

@media (max-width: 960px) {
    .progress-card-container {
        min-height: 470px !important;
        .content-container {
            width: 100% !important;
            flex-direction: column !important;
            .progress-card-infos-container {
                flex-direction: row !important;
                padding: 24px 0 0 !important;
            }
        }
    }
}

@media (max-width: 480px) {
    .progress-card-container {
        min-height: 396px !important;

        .content-container {
            .progress-card-infos-container {
                padding: 16px 0 0 !important;
            }
        }
    }
}


</style>
