<template>
    <div class="progress-title-container">
        <h2>{{ $t(`ProductionProgress.ProgressTab.ProgressCard.${progressName}.title`) }}</h2>
        <h3>{{ $t(`ProductionProgress.ProgressTab.ProgressCard.${progressName}.subtitle`) }}</h3>
    </div>
</template>

<script>
export default {
    props: {
        progressName: {
            type: 'Real' | 'Forecast',
            default: '',
            required: true
        }
    }
}
</script>

<style lang="scss" scoped>
.progress-title-container {
    h2 {
        font-weight: 600;
        font-size: 26px;
        line-height: 36px;
        color: #4C4541;

        margin: 0;
    }

    h3 {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #998F8A;

        margin: 8px 0 0 0;
    }
}

@media (max-width: 480px) {
    .progress-title-container {
        h2 {
            font-size: 18px !important;
            line-height: 26px !important;
        }

        h3 {
            font-size: 12px !important;
            line-height: 16px !important;
        }
    }
}
</style>
