<template>
    <div class="progress-tab-container">
        <div class="cards-container">
            <ProgressCard
                progressName="Forecast"
                :percentage="data.progressData.theoretical_progress"
            />
            <ProgressCard
                progressName="Real"
                :percentage="data.progressData.real_progress"
            />
        </div>
        <div class="quantity-info">
            <ProgressInfo :data="data.progressInfoData" />
        </div>
        <div class="status-info">
            <StatusInfo :data="data.statusData"/>
        </div>
    </div>
</template>

<script>
import { mapMutations } from 'vuex';
import ProgressCard from './components/ProgressCard';
import ProgressInfo from './components/QuantityInfo';
import StatusInfo from './components/StatusInfo';

export default {
    components: {
        ProgressCard,
        ProgressInfo,
        StatusInfo
    },
    props: {
        data: {
            type: Object,
            default: () => ({})
        },
    },
    mounted() {
        this.UPDATE_FLAG_SHOW_OVERLAY(false);
    },
    methods: {
        ...mapMutations('app', [
            'UPDATE_FLAG_SHOW_OVERLAY'
        ])
    }
}
</script>

<style lang="scss" scoped>
.progress-tab-container {
    z-index: 9999;
    display: flex;
    flex-direction: column;
    padding: 24px 29px;
    flex-grow: 1;
    margin-bottom: 0.5rem;

    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-track {
        background: #fff;
    }

    &::-webkit-scrollbar-thumb {
        background-color:  #CFC4BE;
        border-radius: 9px;
    }

    .cards-container {
        display: flex;
        gap: 16px;
    }

    .quantity-info {
        margin-top: 24px;
    }
}

@media (max-width: 620px) {
    .progress-tab-container {
        .cards-container {
            flex-direction: column !important;
        }
    }
}

@media (max-width: 480px) {
    .progress-tab-container {
        padding: 24px 16px !important;
    }
}
</style>
